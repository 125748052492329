
import axios from 'axios'
 function parseError (messages) {
   // error
   if (messages) {
     if (messages instanceof Array) {
       return Promise.reject({ messages: messages })
     } else {
       return Promise.reject({ messages: [messages] })
     }
   } else {
     return Promise.reject({ messages: ['Bir hata oluştu'] })
   }
 }
 
 function parseBody (response) {
   if (response.status === 200) {    
     return response
   } else {
     return this.parseError(response.data.messages)
   }
 }
 
 let instance = axios.create({
    baseURL:`https://api.sahin724.com/api`,//`http://localhost:3215/api`, 
    headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
 })
 
 instance.interceptors.request.use((config) => {
   const apiToken = localStorage.getItem('access_token')
   config.headers = { 
     Authorization: `Bearer ${apiToken}`
   }

   return config
 }, error => {
   return Promise.reject(error)
 })
 
 instance.interceptors.response.use((response) => {
   return parseBody(response)
 }, error => {
   if(error.response.status === 401){
    localStorage.removeItem('access_token');
    localStorage.removeItem('loggedIn');
    window.location.replace('/');

 }
   if (error.response) {
     return parseError(error.response.data)
   } else {
     return Promise.reject(error)
   }
 })
 
 export const http = instance