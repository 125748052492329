import * as ProductListAction from '../types/productType';

const initialState = {
  productList: JSON.parse(localStorage.getItem('productList')) ? JSON.parse(localStorage.getItem('productList')) : [],
  cart: JSON.parse(localStorage.getItem('state')) ? JSON.parse(localStorage.getItem('state')) : [],
  freecart: [],
  currentItem: null,
  productListFetchCount: 0,
  productListFetchState: ProductListAction.ProductListFetchStates.IDLE,
};

export default function ProductListReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ProductListAction.START_FETCH_PRODUCT_LIST:
      return {
        ...state,
        productListFetchState: ProductListAction.ProductListFetchStates.FETCHING,
      };
    case ProductListAction.FINISH_FETCH_PRODUCT_LIST:
      return {
        ...state,
        productListFetchState: ProductListAction.ProductListFetchStates.FETCHED,
        productListFetchCount: state.productListFetchCount + 1,
      };
    case ProductListAction.ERROR_FETCH_PRODUCT_LIST:
      return {
        ...state,
        productListFetchState: ProductListAction.ProductListFetchStates.ERROR,
      };
    case ProductListAction.LOAD_PRODUCT_LIST:
      return {
        ...state,
        productList: payload,
      };
    case ProductListAction.ADD_TO_CART:
      const item = state.productList.find(
        (product) => product.ProductId === payload
      );

      // Check if Item is in cart already
      const inCart = state.cart.find((item) =>
        item.ProductId === payload ? true : false
      );

      const cart = inCart
        ? state.cart.map((item) =>
          item.ProductId === payload
            ? { ...item, qty: item.qty + 1 }
            : { ...item }
        )
        : item !== undefined ? [{ ...item, qty: 1 }] : [];
      const localCard = JSON.parse(localStorage.getItem('state'));
      if (localCard !== null) {
        const inLocalStorage = localCard.find((item) =>
          item.ProductId === payload ? true : false
        );
        if (!inLocalStorage || payload === undefined) {
          localCard.forEach((product) => {
            cart.push(product);
          });

        }

      }

      if (item !== undefined) {
        import('react-ga4')
          .then((x) => x.default)
          .then((ReactGA) => {
            ReactGA.event("add_to_cart",{
              currency: 'TRY',
              value: item.Price*item.Piece,
              items: [{
                item_id: payload.id,
                item_name: item.ProductName,
                quantity: item.Piece,
                item_brand	: 'Şahin',
                item_category: item.CategoryName,
                price: item.Price 
              }]
            });  

          })
        import('react-facebook-pixel')
          .then((x) => x.default)
          .then((ReactPixel) => {
            ReactPixel.init('184192118716429') // facebookPixelId
            ReactPixel.pageView()
            ReactPixel.fbq('track', 'AddToCart', {
              content_ids: '[' + payload + ']',
              content_type: 'product',
              value: item.Price,
              currency: 'TRY'
            })
          })
      }



      localStorage.setItem('state', JSON.stringify(cart));

      return {
        ...state,
        cart: cart,
      };
    case ProductListAction.FREE_PRODUCT_ADD_TO_CART: 

      let totalPrice = 0;
      // Great Item data from products array
      const freeitem = state.productList.find(
        (product) => product.ProductId === payload.id
      );

      // Check if Item is in cart already
      const freeinCart = state.freecart.find((item) =>
        item.ProductId === payload.id ? true : false
      );

      const freecart = freeinCart
        ? state.freecart.map((item) =>
          item.ProductId === payload.id
            ? { ...item, qty: item.qty + 1 }
            : { ...freeitem }
        )
        : freeitem !== undefined ? [{ ...freeitem, qty: 1 }] : [];

      const freelocalCard = JSON.parse(localStorage.getItem('cartFreeProduct'));
      if (freelocalCard !== null && freelocalCard.length>0 ) { 
      /*
        const freeinLocalStorage = freelocalCard.find((item) =>
          item.Id === payload.id ? true : false
        );
       if (!freeinLocalStorage || payload === undefined) {
          freelocalCard.forEach((product) => { 
              freecart.push(product);
          }); 
        }
         */  
          let InBask=false;
          for (var i = 0; i < freelocalCard.length; i++) {
            if (freelocalCard[i].ProductId === payload.id) { 
              freelocalCard[i].qty = freelocalCard[i].qty + 1;  
              InBask=true;
            } 
          }

          if(InBask===false) 
            {   
              freelocalCard.push(freecart[0]); 
            }

            localStorage.setItem('cartFreeProduct', JSON.stringify(freelocalCard));

      }else{ 
        //added 19-79-23 
        localStorage.setItem('cartFreeProduct', JSON.stringify(freecart));
        const freelocalCard = JSON.parse(localStorage.getItem('cartFreeProduct'));
         
          freelocalCard.forEach((product) => {
            freecart.push(product);
          }); 
      }
      //freecart.forEach((product) => {
        //totalPrice = totalPrice + (product.Price * product.qty);
      //})

      //if (totalPrice <= payload.totalPrice) {
        //localStorage.setItem('cartFreeProduct', JSON.stringify(freecart));
      //}
      //else {
      //}
 
      return {
        ...state,
        freecart: freecart,
      };
      case ProductListAction.REMOVE_FREE_PRODUCT_CART_ITEM:

        let freeLocalCart2 = JSON.parse(localStorage.getItem('cartFreeProduct'));
        
        //added new 
        if (freeLocalCart2 !== null) {
          for (var i = 0; i < freeLocalCart2.length; i++) { 
            //alert(payload.id);
          
            if (freeLocalCart2[i].ProductId === payload.id && freeLocalCart2[i].qty>0) {
              freeLocalCart2[i].qty = freeLocalCart2[i].qty - 1; 
              if (freeLocalCart2[i].qty==0){ 
                freeLocalCart2.splice(i, 1);
              }
              break;
            } 
          }
           
          localStorage.setItem('cartFreeProduct', JSON.stringify(freeLocalCart2)); 
        }
        //added end

        /*
        if (freeLocalCart2 !== null) {
         
          let newCart1 = freeLocalCart2.filter((item) => item.ProductId === payload.id);
          if (newCart1[0].qty !== 1) {
            newCart1[0].qty = newCart1[0].qty - 1;
            localStorage.setItem('cartFreeProduct', JSON.stringify(newCart1)); 
          }
          else {
            newCart1 = freeLocalCart2.filter((item) => item.ProductId !== payload.id);
            localStorage.setItem('cartFreeProduct', JSON.stringify([]));
            return {
              ...state,
              freecart: [],
            };
          }
           
          //freecart.forEach((product) => {
            //totalPrice = totalPrice + (product.Price * product.qty);
          //})
          
        }*/
        return {
          ...state,
          freecart: [],
        };
    case ProductListAction.REMOVE_FROM_CART:
      const removeCart = JSON.parse(localStorage.getItem('state'));
      if (removeCart !== null) {
        const removedCartItem = removeCart.filter((item) => item.ProductId !== payload);
        localStorage.setItem('state', JSON.stringify(removedCartItem));
        return {
          ...state,
          cart: removedCartItem,
        };
      }
      return {
        ...state,
        cart: [],
      };
    case ProductListAction.REMOVE_CART_ITEM:
      let localCart2 = JSON.parse(localStorage.getItem('state'));
      if (localCart2 !== null) {
        let newCart1 = localCart2.filter((item) => item.ProductId === payload);
        if (newCart1[0].qty !== 1) {
          if (newCart1[0].Unit === "Adet") {
            if (newCart1[0].qty !== 1) {
              newCart1[0].qty = newCart1[0].qty - 1;
            }
            else {
              newCart1 = localCart2.filter((item) => item.ProductId !== payload);
            }
          }
          else {
            if (newCart1[0].qty !== 0.25) {
              newCart1[0].qty = newCart1[0].qty - 1;
            }
            else {
              newCart1 = localCart2.filter((item) => item.ProductId !== payload);
            }
          }
        }

        localCart2 = localCart2.filter((item) => item.ProductId !== payload);
        if (newCart1[0] !== undefined) {
          localCart2.unshift(newCart1[0]);
        }
        localStorage.setItem('state', JSON.stringify(localCart2));
        return {
          ...state,
          cart: localCart2,
        };
      }
      return {
        ...state,
        cart: [],
      };

    case ProductListAction.ADJUST_ITEM_QTY:
      // Great Item data from products array
      const item2 = state.productList.find(
        (product) => product.ProductId === payload.id
      );

      // Check if Item is in cart already
      const inCart2 = state.cart.find((item) =>
        item.ProductId === payload.id ? true : false
      );

      const cart2 = inCart2
        ? state.cart.map((item) =>
          item.ProductId === payload.id
            ? { ...item2, qty: item.qty + payload.qty }
            : { ...item2 }
        )
        : item2 !== undefined ? [{ ...item2, qty: payload.qty }] : [];

      const localCard2 = JSON.parse(localStorage.getItem('state'));
      if (localCard2 !== null) {
        const control = localCard2.find(item => item.ProductId === payload.id);
        if (control) {
          const newCart2 = []
          const prevCart2 = localCard2.filter(item => item.ProductId !== payload.id);
          control.qty = payload.qty + control.qty;
          prevCart2.forEach((product) => {
            newCart2.push(product);
          });
          newCart2.push(control);
          localStorage.setItem('state', JSON.stringify(newCart2));


        }
        else {

          if (payload !== undefined) {
            localCard2.forEach((product) => {
              cart2.push(product);
            });
            localStorage.setItem('state', JSON.stringify(cart2));

          }
        }




      }
      else {
        localStorage.setItem('state', JSON.stringify(cart2));

      }

      if (item2 !== undefined) {
        import('react-ga4')
          .then((x) => x.default)
          .then((ReactGA) => {
            ReactGA.event("add_to_cart",{
              currency: 'TRY',
              value: item2.Price*item2.Piece,
              items: [{
                item_id: payload.id,
                item_name: item2.ProductName,
                quantity: item2.Piece,
                item_brand	: 'Şahin',
                item_category: item2.CategoryName,
                price: item2.Price 
              }]
            }); 
          })
          import('react-facebook-pixel')
          .then((x) => x.default)
          .then((ReactPixel) => {
            ReactPixel.init('184192118716429') // facebookPixelId
            ReactPixel.pageView()
            ReactPixel.fbq('track', 'AddToCart', {
              content_ids: '[' + payload.id + ']',
              content_type: 'product',
              value: cart2.map(product => { let y = 0; y += product.qty * product.Price; return y; })[0],
              currency: 'TRY'
            })
          })
      }



      return {
        ...state,
        cart: cart2,
      };
    case ProductListAction.LOAD_CURRENT_ITEM:
      return {
        ...state,
        currentItem: payload,
      };
    case ProductListAction.CAMPAING_CHECK:
      return {
        ...state,
        cartList: payload,
      }
    default:
      return state;
  }
}